@import "../main.scss";

@mixin icon($color) {
  span {
    display: flex;
    align-items: center;
  }
  span svg {
    height: 25px;
    width: 25px;
    margin: auto;
    fill: $color;
  }
}

@mixin arrow($vertical, $horizontal) {
  content: " ";
    position: absolute;
    top: $vertical;
    left: $horizontal; 
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;

    @if $horizontal == 100% {
      border-color: transparent transparent transparent $textColor-secondary; 
    } @else if $horizontal == -10px {
      border-color: transparent $textColor-secondary transparent transparent ; 
    } @else if $vertical == 115% {
      border-color: $textColor-secondary transparent transparent transparent ; 
    }@else if $vertical == -15% {
      border-color: transparent transparent $textColor-secondary transparent ; 
    }
}
.btn {
  cursor: pointer;
  border-radius: 50px;
  border: none;
  font-family: $font-primary;
  position: relative;
  

  .tooltiptext {
    visibility: hidden;
    min-width: 120px;
    background-color: $bg-secondary;
    border: 1px $textColor-secondary solid;
    color: $textColor-primary;
    font-family: $font-primary;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 6px;

    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
  }
  .left {
    right: 115%;
  }
  .right {
    left: 115%;
  }
  .left {
    right: 115%;
  }
  .top {
    top: -100%;
  }
  .down {
    top: 115%;
  }
}
.btn:focus {
  outline: none;
}
.btn:active {
  animation: pulse 1s;
}
.btn:hover .tooltiptext {
  visibility: visible;
}

.btn-primary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  align-items: center;
  padding: 10px 20px;
  background: $bg-alt;
  min-width: 180px;
  color: $textColor-primary;
  font-weight: bolder;
  font-size: 16px;
  @include icon($textColor-primary);
}
.btn-primary:hover {
  transform: scale(1.02);
  transition-duration: 0.5s;
}

.btn-secondary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  align-items: center;
  padding: 10px 20px;
  background-color: rgba($color: $textColor-secondary, $alpha: 0.1);
  min-width: 180px;
  color: $textColor-primary;
  font-weight: bolder;
  font-size: 16px;
  @include icon($textColor-primary);
  border: 1px $textColor-secondary solid;
  box-sizing: border-box;
}
.btn-secondary:hover {
  background-color: rgba($color: $textColor-secondary, $alpha: 0.3);
}

// Icon buttons

.btnIcon {
  width: 46px;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background-color: rgba($color: $textColor-secondary, $alpha: 0.1);
  box-sizing: border-box;
  @include icon($textColor-primary);
}
.btnIcon:hover {
  background-color: rgba($color: $textColor-secondary, $alpha: 0.3);
}

.btnIcon-primary {
  border: 1px solid rgba($textColor-primary, 0.91);
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}
