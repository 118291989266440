@import "../main.scss";

.basicInformation {
  padding-top: 90px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  > img {
    object-fit: cover;  
    width: 100%;
    height: 312px;
    border-radius: 30px;
  }
  
  > span {
    width: 100%;
    height: 312px;
    border-radius: 30px;
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
.listAbout {
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 40px 0px;
}

@media only screen and (min-width: 450px) {
  .basicInformation {
    flex-direction: row;
    justify-content: center;

    > img {
      min-width: 35vw;
    }
    > span {
      min-width: 35vw;
    }
  }

  .listAbout {
    flex-direction: row;
    justify-content: center;
    gap: 30px;
  }
}

@media only screen and (min-width: 900px) {
  .basicInformation {
      gap: 70px;
    > img {
      min-width: 400px;
      width: 400px;
      height: 420px;
    }
    > span {
      min-width: 400px;
      width: 400px;
      height: 420px;
    }
    > div {
      width: 400px;
      gap: 30px;
      h3{
          margin-bottom: 10px;
          margin-top: -20px;
      }
    }
  }

  .listAbout {
      margin-top: 140px;
      gap: 70px;
  }
}
