@import "../../main.scss";

.contacme {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 100px;
  margin-top: 200px;
  padding-bottom: 10px;

  > h3 {
    display: none;
  }

  > div {
    text-align: center;
  }

  .contactme_reachOut {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}
.social {
  display: flex;
  justify-content: space-between;
  gap: 25px;
}

@media only screen and (min-width: 450px) {
  .contacme {
    > h3 {
      text-align: center;
      display: inline-block;
    }
  }
}
