@import "../main.scss";

.presentation {
  bottom: 0px;
  gap: 19px;
  padding: 20px 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .profile {
    height: 150px;
    width: 150px;
    border-radius: 100%;
    box-sizing: border-box;

    box-shadow: 1px 1px 31px -5px rgba(63,15,255,0.75);
    -webkit-box-shadow: 1px 1px 31px -5px rgba(63,15,255,0.75);
    -moz-box-shadow: 1px 1px 31px -5px rgba(63,15,255,0.75);
  }    

  .information {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 30px;
  }

  .contact {
    display: flex;
    gap: 10px;
    width: 100%;
  }

  .contact__options {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
}

.presentation__details {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  > p {
    max-width: 400px;
  }

  > div {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (min-width: 450px) {
  .presentation {
    left: 85px;
    right: 85px;
    bottom: 25px;
    .brand{
        width: 400px;
        min-height: 400px;
    }
    
    .first {
        width: 100px;
        height: 100px;
        top: -22px;
        right: -22px;
    }
    
    .second {
        width: 100px;
        height: 100px;
        left: -22px;
        bottom: -22px;
    }
    
    .information {
        justify-content: center;
    }
  }
  
}

@media only screen and (min-width: 900px) {
  .presentation {
    flex-direction: row-reverse;
    justify-content: center;
    bottom: 65px;
    min-height: 650px;

    .brand{
        min-width: 400px;
        height: 400px;
    }

    .first {
        width: 100px;
        height: 100px;
        top: -22px;
        right: -22px;
    }
    
    .second {
        width: 100px;
        height: 100px;
        left: -22px;
        bottom: -22px;
    }

    .information {
        justify-content: center;
    }
    
    .contact {
      width: auto;
    }
  }
  .presentation__details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .presentation__details > div {
    display: flex;
    flex-direction: column;
  }
  .presentation__details > p {
    max-width: 500px;
  }
}
