@import "../../main.scss";

.header-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 40px;
  align-items: center;
  padding: 0px;
  list-style: none;
  margin: 0px -35px;
  padding: 0px 35px;
  z-index: 500;
  position: fixed;
  top: 0px;
  left: 35px;
  right: 35px;
  background-color: $bg-primary;

  span {
    padding: 2px;
    border-radius: 5px;
  }
}

.header-list__item {
  font-family: $font-primary;
  font-weight: 600;
  font-size: 16px;

  a {
    padding: 20px 0px;
    display: inline-block;
    color: $textColor-primary;
    text-decoration: none;
  }
}

.header-list__item__active{
  span {
  background: $bg-alt;
  transition-duration: 0.5s;
  }
}

@media only screen and (min-width: 1000px) {
  .header-list {
    max-width: 900px;
    margin: auto;
  }
  .header-list__item {
    font-size: 18px;
  }
}
