@import "../main.scss";

.tag {
    padding: 5px 10px;
    background-color: rgba($color: $textColor-secondary, $alpha: 0.1);
    box-sizing: border-box;
    border-radius: 15px;
    font-size: 12px;
    font-weight: 900;
    font-family: $font-primary;
    color: $textColor-secondary;
}

.icon-tag {
    padding: 5px 10px;
    background-color: rgba($color: $textColor-secondary, $alpha: 0.1);
    box-sizing: border-box;
    border-radius: 15px;
    font-size: 16px;
    font-weight: 900;
    line-height: 0px;
    font-family: $font-primary;
    color: $textColor-secondary;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    width: fit-content;
}