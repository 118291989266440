@import "../../main.scss";

.animation-lazy {
    background-color: $bg-primary;
    min-height: 20px;
    animation-name: lazy;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

@keyframes lazy {
  from {
    background-color: $bg-primary;
  }
  to {
    background-color: $bg-secondary;
  }
}
