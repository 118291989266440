@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");

// COLORS STACK
$bg-primary: #07090e;
$bg-secondary: #1c2431;
$bg-alt: linear-gradient(90deg, rgba(255,38,178,1) 0%, rgba(133,27,217,1) 64%, rgba(63,15,255,1) 100%);
$textColor-primary: #ffffff;
$textColor-secondary: #b9c1d0;

// FONTS STACK
$font-primary: "Open Sans", sans-serif;
$font-secondary: "Raleway", sans-serif;
// $font-title:

body {
  margin: 0px 35px;
  background-color: $bg-primary;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  text-decoration: none;
  color: none;
}
@media only screen and (min-width: 1000px) {
  body {
    width: 900px;
    margin: auto;
  }
}

.scrollHorizontal::-webkit-scrollbar {
  width: 0px;
}
.scrollHorizontal::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);  */
  /* border-radius: 10px; */
}
.scrollHorizontal::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 0px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); */
}
