@import "../main.scss";

.tech-stack {
  display: flex;
  flex-direction: column;
  gap: 20px;

  > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
  }
}

@media only screen and (min-width: 450px) {
  .tech-stack {
    min-width: 35vw;
  }
}

@media only screen and (min-width: 900px) {
  .tech-stack {
    min-width: 400px;
    width: 400px;
  }
}
