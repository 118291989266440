@import "../main.scss";

.project-list {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}


.bigCardProject {
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  background: rgba($bg-secondary, 0.66);
  border-radius: 15px;
  gap: 20px;

  .project-presentation {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 0px;

    img {
      border-radius: 10px;
      min-height: 100%;
      object-fit: cover;
    }
  }

  .information {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .information__technologies {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 5px;
    }
  }

  div {
    display: flex;
    gap: 5px;
  }
}

.bigCardProject:hover {
  background-color: $bg-secondary;
}

.cardProject {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  max-height: 620px;
  min-height: 430px;
  background: rgba($bg-secondary, 0.66);
  border-radius: 30px;
  gap: 10px;
}
.cardProject:hover {
  background-color: $bg-secondary;
}

.cardProject__head {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 46px;
  width: 100%;
  gap: 10px;
}

.cardProject__image {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cardProject__footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@keyframes zoomIn {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.001);
  }
}
@keyframes zoomOut {
  0% {
    transform: scale(1.001);
  }
  100% {
    transform: scale(1);
  }
}

@media only screen and (min-width: 450px) {

  .cardProject {
    max-height: 750px;
    max-width: 500px;
  }
  .cardProject__footer {
    justify-content: flex-start;
    gap: 10px;
  }
}

@media only screen and (min-width: 900px) {
  .bigCardProject {
    flex-direction: row;
    padding: 30px 20px;
    border-radius: 30px;
    gap: 40px;

    .project-presentation {
      width: 40%;
    }
  }
}
