@import "../../main.scss";

.forms {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  margin: auto;

  img {
    width: 100%;
  }
  span {
    width: 100%;
    height: 200px;
  }
}

@media only screen and (min-width: 900px) {
  .forms {
    width: 80%;
  }
}
