@import "../main.scss";

.project {
  padding-top: 90px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  > img {
    border-radius: 30px;
    width: 100%;
    object-fit: cover;
  }
  >span {
    border-radius: 30px;
    height: 200px;
  }
}

.project__data {
  display: flex;
  flex-direction: column;
  > h3 {
    margin-bottom: 0px;
    font-family: $font-primary;
  }
  > h5 {
  font-size: 20px;
  }
  > div {
    margin-top: 20px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
}

.project-section {
  padding: 30px 0px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  .project-image{
    width: 100%;
    border-radius: 10px;
  }

  > div {
    > h4 {
      margin-bottom: 10px;
    }
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: auto;
  }

  .element-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

@media only screen and (min-width: 900px) {
  .project {
    margin: auto;

    >span {
      border-radius: 30px;
      height: 500px;
    }
  }
  .project-section {
    padding: 40px 0px;
    gap: 50px;
    width: 100%;

    .project-image{
      border-radius: 10px;
      width: 100%;
      min-height: 400px;
      object-fit: cover;
    }

    > div {
      max-width: 100%;
    }
  }
}
