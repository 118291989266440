@import "../../main.scss";

.summary {
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  background: rgba($bg-secondary, 0.66);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  box-sizing: border-box;

  h4 {
    margin-bottom: 10px;
  }
}

.actions {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

@media only screen and (min-width: 900px) {
  .summary {
    grid-template-columns: repeat(2, 1fr);
    padding: 40px;
    gap: 40px;
    border-radius: 20px;
    
    .tools {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px;
      
      > li {
        min-width: 40%;
        margin-right: 20px;
      }
    }
  }
}
