@import "../main.scss";

.root-not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  gap: 5px;
  text-align: center;

  h5 {
    margin-top: 41px;
  }
  p {
    margin-bottom: 15px;
  }
}

._404 {
  font-weight: 800;
  font-size: 11rem;
  font-family: $font-secondary;
  color: $textColor-secondary;
  text-shadow: 10px 10px $bg-secondary;
}
