.vyrx_app-screens {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
  margin: auto;

  img {
    width: 100%;
    border-radius: 10px;
  }

  span {
    //width: 100%;
    height: 300px;
    border-radius: 10px;
  }
}

.vyrx_app-sketchs {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(3, 400px);
  column-gap: 20px;
  row-gap: 20px;
  width: 100%;
  margin: auto;

  div {
    width: 100%;
    border-radius: 20px;
    img {
        
    object-fit: cover;  
    width: 100%;
    }
  }

  span {
    height: 300px;
    border-radius: 20px;
  }
}

@media only screen and (min-width: 900px) {
  .vyrx_app-screens {
    grid-template-columns: repeat(3, 1fr);
    width: 80%;
    gap: 40px;
    img {
      border-radius: 20px;
    }
    span {
      border-radius: 20px;
    }
  }
  .vyrx_app-sketchs {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 300px);
    column-gap: 20px;
    row-gap: 20px;
    width: 80%;

    .vyrx_app-sketchs__home {
      grid-column: 1;
      grid-row: 1/3;
    }
  }
}
